<template>
  <div class="box">
    <!-- 搜索 -->
    <van-sticky :offset-top="0">
      <van-search v-model="search"
        placeholder="请输入搜索关键词"
        @search="onSearch"
        shape="round"
        left-icon="https://lyxnyapi.dexian.ren//upload/images/2022/7/714ba889-821.png"></van-search>
      <div class="searchOn"
        @click="onSearch()">搜索</div>
    </van-sticky>
    <!-- 商品列表 -->
    <div class="lists">
      <div class="listBox">
        <div class="list"
          @click="detailClick(item.PromotionId)"
          v-for="item in list"
          :key="item.PromotionId">
          <img :src="item.PosterUrl" />
          <div class="listRight">
            <div>{{item.Title}} <br /> <span style="font-size: 12px;font-weight: 400;">{{item.ShopName}}</span> </div>
            <div class="titleC">
              <!-- <img src="../../assets/shop/shopa.png">
              <span style="font-size:16px;color:#F09B0B">{{item.Level}}</span> -->
              <img src="../../assets/shop/shopb.png">
              <span>{{item.StartDate}} ~ {{item.EndDate}}</span>
            </div>
            <br>
            <div class="titleC">
              <img src="../../assets/shop/shopc.png">
              <span>{{item.OrgName}}</span>
            </div>
          </div>
        </div>
        <van-empty :image-size="[60, 40]"
          description="暂无相关商铺信息~"
          v-if="!list.length" />
      </div>
    </div>
  </div>
</template>
<script>
import { WxGetPromotionPage } from "@/api/shop";
import { getOpenId } from "@/utils/auth";
export default {
  data () {
    return {
      active: 0,
      listfrom: {
        page: 1,
        limit: 10,
      }, //查询传参
      list: [],//内容
      search: undefined,
    }
  },
  mounted () {
    console.log(this.$route.query);
    this.listfrom.shopTypeKind = this.$route.query.id;
    this.getList();
  },
  methods: {
    // 点击进入商户详情
    detailClick (id) {
      // var url = '/shop/detail?Id=' + id
      // this.$router.push(url);
      var url = '/shop/PromotionXq/' + id
      this.$router.push(url);
    },
    // 搜索
    onSearch () {
      this.listfrom.page = 1;
      this.getList();
    },
    // 获取商铺列表
    getList () {
      this.listfrom.title = this.search;
      WxGetPromotionPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].Poster == "") {
                  this.list[i].PosterUrl =
                    "https://lyxnyapi.dexian.ren//upload/images/2022/7/b776c8ca-b1f.png";
                }
                if (this.list[i].StartDate != "") {
                  this.list[i].StartDate = this.list[i].StartDate.substring(0, 10);
                  console.log(this.list[i].StartDate);
                }
                if (this.list[i].EndDate != "") {
                  this.list[i].EndDate = this.list[i].EndDate.substring(0, 10);
                }
              }
            }
          }
        })
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
}
</script>
<style scoped>
.box {
  background: #f5f5f5;
}

/deep/.van-tabs__wrap {
  height: 85px;
}

.lists {
  display: inline-block;
  float: left;
  width: 95%;
  margin: 10px 2.5%;
  overflow-y: scroll;
}

.lists .listBox {
  background: #fff;
  border-radius: 8px;
}

.lists .list {
  display: flex;
  position: relative;
}

.lists .list img {
  width: 85px;
  height: 85px;
  border-radius: 25px;
  padding: 8px;
}

.lists .list .listRight {
}

.list .listRight div:nth-child(1) {
  float: left;
  font-size: 14px;
  font-weight: 700;
  margin: 5px 5px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.list .listRight div:nth-child(2) {
  /* margin: 0px 5px;
    position: absolute;
    bottom: 3px;
    width: 55%; */
}

.list .listRight .addcar {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #ffca36;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  position: absolute;
  right: 0px;
}

.shopbox {
  width: 95%;
  margin: 10px 2.5%;
  padding-bottom: 15px;
}

.shopbox .goodsList img {
  width: 95px;
  height: 70px;
  border-radius: 8px;
}

.shopbox .goodsList div:nth-child(3) {
  color: #ff4a26;
  margin-top: 3px;
}

.shopbox .goodsList div:nth-child(2) {
  font-size: 12px;
  width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/deep/.shopbox .van-swipe__indicators {
  display: none;
}

.list .listRight .titleC {
  float: left;
  display: flex;
  font-size: 12px;
  margin: 3px 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
}

.list .listRight .titleC span {
}

.list .listRight .titleC img {
  width: 15px;
  height: 15px;
  padding: 0px 5px;
  margin: 0px;
}
.searchOn {
  position: absolute;
  top: 12px;
  right: 15px;
  background: linear-gradient(to bottom right, #ffe03e, #ffbe00);
  border-radius: 15px;
  padding: 6px 18px;
  color: #3a3a3a;
  font-size: 14px;
  font-weight: 700;
}
</style>